import React from "react"
import styled from "styled-components"
import { Container } from "../layoutComponents"
import { ButtonPrimary } from "../buttons"
import Breadcrumb3Links from "../breadcrumbs/breadcrumb3links"
import ClinicInfoGrid from "../clinicInfoGrid"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
`

const Bg = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / span 2;
  background: url("../../images/pacha-mamma-banner.jpg") rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1000;
`

const Top = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  color: var(--txt-light);
  padding: 4em 0;

  h2,
  p {
    max-width: 70ch;
  }

  p {
    color: var(--txt-light-secondary);
  }
`

const Bottom = styled.div`
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  z-index: 1;
`

const StyledAnchorLink = styled(props => <AnchorLink {...props} />)`
  cursor: pointer;
  display: inline-block;
  font-size: var(--fs-sm);
  font-weight: var(--fw-button);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1em 1.33em;
  transition: all 0.25s linear;

  background: var(--clr-accent);
  color: var(--txt-light);
  border: 1px solid var(--clr-accent);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;

  &:hover {
    background: var(--clr-accent-darker);
    color: var(--txt-light);
  }

  // this is for when an icon is used inside too
  div {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * + * {
      margin-left: 0;
      padding-left: 0;
    }
  }
`

// banner with only 2 links in the breadcrumb
export default function BannerArchetype(props) {
  return (
    <Wrapper>
      <Bg />
      <Top>
        <Container className="spacing">
          <div>
            <Breadcrumb3Links
              to1={props.to1}
              link1={props.link1}
              to2={props.to2}
              link2={props.link2}
              to3={props.to3}
              link3={props.link3}
            />
            <hr />
            <h2 className="title bold">{props.title}</h2>
          </div>
          <p className="body--small">{props.description}</p>
          <StyledAnchorLink to="/treatments/archetype#archetypeform">
            see our programs
          </StyledAnchorLink>
        </Container>
      </Top>
      <Bottom>
        {/* <Container>
          <ClinicInfoGrid />
        </Container> */}
      </Bottom>
    </Wrapper>
  )
}
