import React from "react"
import styled from "styled-components"

import { Section, Container, Flex, FlexMobileOpp } from "../layoutComponents"
import { Label, Input, TextArea, Submit, Select } from "./formItems"
import Map from "../map"

export default function FormArchetype() {
  return (
    <Section>
      <Container>
        <h3 className="title--bold center">
          <span className="italics accent">
            Archetype Program Questionnaire
          </span>
        </h3>
        <div>
          <form
            name="archetype"
            className="spacing"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <Flex>
              <div className="spacing-sm">
                <Label htmlFor="name">* Name:</Label>
                <Input
                  type="name"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="spacing-sm">
                <Label htmlFor="email">* Email:</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
            </Flex>
            <div className="spacing-sm">
              <Label htmlFor="phone">* Phone:</Label>
              <Input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div className="spacing-sm">
              <Label htmlFor="program">* Select Program</Label>
              <Select name="program" id="program" required>
                <option value="program 1: life path intensive">
                  Program 1: Life Path Intensive
                </option>
                <option value="program 2: one-on-one sessions">
                  Program 2: One-on-one Session
                </option>
                <option value="program 3: 10 day bootcamp">
                  Program 3: 10 Day Bootcamp
                </option>
              </Select>
            </div>

            <div className="spacing-sm">
              <Label htmlFor="msg">Additional Questions:</Label>
              <TextArea
                name="msg"
                id="msg"
                cols="30"
                rows="10"
                placeholder="Type your additional questions here..."
                required
              />
            </div>
            <Submit type="submit" id="submit" value="send" />
          </form>
        </div>
      </Container>
    </Section>
  )
}
