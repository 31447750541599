import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSecondary from "../../components/banners/bannerSecondary"
import Why from "../../components/why"
import Benefits from "../../components/benefits"
import Reviews from "../../components/reviews"
import Treatments from "../../components/treatments"
import CallToAction from "../../components/callToAction"
import FeaturedFaq from "../../components/faqs/featuredFaq"
import {
  Actions,
  Container,
  ContainerSmall,
  Flex,
  FlexMobileOpp,
  GridAuto,
  GridThree,
  Section,
} from "../../components/layoutComponents"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorInline } from "../../components/buttons"
import Calander from "../../components/calander"
import styled from "styled-components"
import FormArchetype from "../../components/forms/formArchetype"
import BannerArchetype from "../../components/banners/bannerArchetype"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Program = styled.div`
  max-width: 75ch;
  width: 100%;

  padding: 2em;
  box-shadow: var(--shadow-light-accent);
  border-radius: var(--br);
`

const StyledAnchorLink = styled(props => <AnchorLink {...props} />)`
  cursor: pointer;
  display: inline-block;
  font-size: var(--fs-sm);
  font-weight: var(--fw-button);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1em 1.33em;
  transition: all 0.2s linear;

  background: transparent;
  color: var(--clr-accent);
  border: 1px solid var(--clr-accent);

  &:hover {
    opacity: 0.8;
  }
`

export default function Archetype() {
  return (
    <Layout>
      <SEO
        title="Archetype Practitioner Description"
        description="I am a trained practitioner in the “It Depends On Who’s Looking” Archetype Project. I work with you and the Archetypes you’re in relationship with, to open up your awareness to the things you do and why you do them."
      />
      <BannerArchetype
        title="it depends on who's looking"
        description="An opportunity to redefine your life
      and craft your destiny.
      "
        to1="/"
        link1="home"
        to2="/our-treatments"
        link2="treatments"
        to3="/treatments/archetype"
        link3="archetype"
      />
      <Section>
        <Container className="spacing center">
          <h1 className="title bold caps">Awareness, Choice, Action</h1>
          <p className="italics">
            What if you were suddenly free of an old trauma? <br /> What if
            feelings of fear and anxiety were a thing of the past? <br /> What
            if illness had no power over you in any way? <br /> What if you no
            longer felt stuck and life suddenly worked? <br /> What would life
            look like then?
          </p>
          <p>
            I am a trained practitioner in the “It Depends On Who’s Looking”
            Archetype Project. I work with you and the Archetypes you’re in
            relationship with, to open up your awareness to the things you do
            and why you do them. Through this awareness, I help you renegotiate
            your life path. Are you ready to become aware?!
          </p>
          <StaticImage
            src="../../images/kathryn-kessler-calgary-rmt.jpg"
            alt="kathryn kessler"
            className="stretch"
            height={600}
          />
        </Container>
        <Container>
          <GridAuto>
            <StaticImage
              src="../../images/archetypes/archetype-1.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-2.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-3.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-4.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-5.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-6.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-7.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-8.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-9.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-10.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-11.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-12.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-13.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-14.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-15.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-16.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-17.JPG"
              alt="archetypes"
            />
            <StaticImage
              src="../../images/archetypes/archetype-18.JPG"
              alt="archetypes"
            />
          </GridAuto>
        </Container>
      </Section>
      <Section className="spacing">
        <Container className="spacing center">
          <h2 className="title bold caps">Archetypes</h2>
          <p className="italics">
            Ancient primal forces of the Universe. <br />
            Forces which influence our every thought and deed; with or without
            our awareness of them.
          </p>
          <p>
            Using Shamanic techniques, this program integrates Numerology,
            Tarot, Astrology and Mythology into a fascinating look at you and
            the life path you set up for yourself. You will examine the
            contracts you have made with The Archetypes and gain a clear
            understanding of why some of these contracts are responsible for
            much of what is not working in your life. Knowledge is power and you
            are about to be in a position of power to initiate change! The
            probabilities in your life will become obvious. But, it’s the
            possibilities for your life that you’ll want to explore. There will
            be plenty of time to do just that during your choice of one of the
            programs (below) I have to offer.
          </p>
        </Container>
        <ContainerSmall>
          <GridThree>
            <Program className="spacing">
              <div className="spacing">
                <h3 className="body center">
                  Program 1: <br />
                  <span className="upper bold italics accent body--large">
                    life path intensive
                  </span>
                </h3>
                <p>
                  Is it time to take an in-depth look at your life? You have
                  come to the right place. Take a look at all these energies.
                  Which ones are readily available to you? Which ones do you
                  have to work for? Come and find your why in this 90 minute
                  One-on-One session.
                </p>
                <p>
                  Discipline, transformation, fairness, pioneering, trust in the
                  universe, play, humanitarianism, emotional turbulence,
                  decisiveness, self love, confidence, sacrifice, grandeur,
                  empathy, healing, ambition, aggression, excitement, passion,
                  idealism, quick wittedness, wisdom, sensuality, looking
                  inward, courage, responsibility, will, doing the right thing,
                  name a few.
                </p>
              </div>
              <div>
                <h3 className="caps bold">pricing & process:</h3>
                <p>$149 CAD (tax included) 90 minutes</p>
                <ol>
                  <li>
                    Please forward the completed Questionnaire below by hitting
                    "Send"
                  </li>
                  <li>
                    You will be contacted by one of our Archetype Practitioners
                    by text or email
                  </li>
                  <li>
                    They will set up a Teams conference call with you at a
                    mutually convenient time
                  </li>
                  <li>
                    After they have arranged your session with you, you will be
                    asked to forward an e-Transfer to your Practitioner in the
                    amount of $149 CAD (tax in){" "}
                  </li>
                </ol>
              </div>
              <Actions>
                <StyledAnchorLink to="/treatments/archetype#archetypeform">
                  apply
                </StyledAnchorLink>
              </Actions>
            </Program>
            <Program className="spacing">
              <div className="spacing">
                <h3 className="body center">
                  Program 2: <br />
                  <span className="upper bold italics accent body--large">
                    one-on-one session
                  </span>
                </h3>
                <p>
                  What issue would you like to explore in your life? Would you
                  like to meet the Archetype who is holding up the mirror? Are
                  you struggling with: Dreams never seem to work out, anger,
                  lack of passion for anything, obsessive/compulsive, on an
                  emotional roller-coaster, overly empathetic, lack of faith,
                  can’t stand up for myself, judgemental, frozen with fear,
                  anxiety, to name a few.
                </p>
                <p>
                  You won’t believe how one hour can shift your awareness of a
                  situation, an issue or a circumstance so dramatically!
                </p>
              </div>
              <div>
                <h3 className="caps bold ">pricing & process:</h3>
                <p>$60 CAD (tax included) 60 minutes</p>
                <ol>
                  <li>
                    Please forward the completed Questionnaire below by hitting
                    "Send"
                  </li>
                  <li>
                    You will be contacted by one of our Archetype Practitioners
                    by text or email
                  </li>
                  <li>
                    They will set up a Teams conference call with you at a
                    mutually convenient time
                  </li>
                  <li>
                    After they have arranged your session with you, you will be
                    asked to forward an e-Transfer to your Practitioner in the
                    amount of $60 CAD (tax in){" "}
                  </li>
                </ol>
              </div>
              <Actions>
                <StyledAnchorLink to="/treatments/archetype#archetypeform">
                  apply
                </StyledAnchorLink>
              </Actions>
            </Program>
            <Program className="spacing">
              <div className="spacing">
                <h3 className="body center">
                  Program 3: <br />
                  <span className="upper bold italics accent body--large">
                    10 day bootcamp
                  </span>
                </h3>
                <p>
                  10 days of intense work to completely transform a wound or an
                  issue that’s holding you back, by working directly with an
                  Archetype.
                </p>
                <p>
                  What if you could overcome a life-long issue in as little as
                  10 days? — a problem that possibly years of therapy has failed
                  to address.
                </p>
                <p>
                  10 Days is all it takes — provided, of course, you want to
                  make it happen. Your commitment is paramount.
                </p>
              </div>
              <div>
                <h3 className="caps bold ">pricing & process:</h3>
                <p>$360 CAD (tax included) 60 minutes</p>
                <ol>
                  <li>
                    Please forward the completed Questionnaire below by hitting
                    "Send"
                  </li>
                  <li>
                    You will be contacted by one of our Archetype Practitioners
                    by text or email
                  </li>
                  <li>
                    They will set up a Teams conference call with you at a
                    mutually convenient time
                  </li>
                  <li>
                    After they have arranged your session with you, you will be
                    asked to forward an e-Transfer to your Practitioner in the
                    amount of $360 CAD (tax in){" "}
                  </li>
                </ol>
              </div>
              <Actions>
                <StyledAnchorLink to="/treatments/archetype#archetypeform">
                  apply
                </StyledAnchorLink>
              </Actions>
            </Program>
          </GridThree>
          <div>
            <center>
              <p>
                For more information please visit:{" "}
                <a
                  href="https://www.itdependsonwhoslooking.com/thearchetypes"
                  target="_blank"
                >
                  https://www.itdependsonwhoslooking.com/thearchetypes
                </a>
              </p>
            </center>
          </div>
        </ContainerSmall>
      </Section>

      <div id="archetypeform">
        <FormArchetype />
      </div>
    </Layout>
  )
}
